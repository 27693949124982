/* eslint-disable import/no-unresolved */
import {
    Virtual, Pagination, Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default () => ({
    Swiper,
    SwiperSlide,
    Virtual,
    Pagination,
    Autoplay,
});
